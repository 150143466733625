import React from 'react';

const YouTubeEmbed = ({
  videoId,
  autoplay = true,
  preventMouseEffects = true,
  muted = true,
}) => {
  const src = `https://www.youtube.com/embed/${videoId}?autoplay=${
    autoplay ? 1 : 0
  }&mute=${muted ? 1 : 0}&loop=1&playlist=${videoId}`;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingBottom: '56.25%',
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        }}
        src={src}
        title="Video Player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
      {preventMouseEffects && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div>
  );
};

export default YouTubeEmbed;
