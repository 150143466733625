/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import TextLoop from 'react-text-loop';
import { Link } from 'gatsby';
import Facebook from '../components/icons/facebook';
import Instagram from '../components/icons/Instagram';

import axios from 'axios';

import logo from '../assets/circle-black.png';

import { Box, AspectImage, Text, Flex, Image } from 'theme-ui';
import HeroLeft from '../assets/hero-left.png';
import HeroRight from '../assets/hero-right.jpg';
import Arrow from './icons/arrow';
import GoNationGuy from '../assets/GoNation_Guy.svg';
import Down from './icons/down';
import cloudinaryOptimize from '../helpers/cloudinaryHelper';
import YouTubeEmbed from './YoutubeEmbed';

let jsonpAdapter = require('axios-jsonp');
const SplashBlock = () => {
  const [shoutData, setShoutData] = useState({});

  const shoutURL =
    'https://data.prod.gonation.com/profile/shoutsnew/bzn-PKzc8iPtRTSo0pG0hel46g';

  useEffect(() => {
    axios({
      url: shoutURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setShoutData(res.data);
    });
  }, []);

  const addHTTP = url => {
    const prefix = 'https://';
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url;
    }
    return url;
  };

  const renderCTA = () => {
    const buttonNames = Object.keys(shoutData.shout.ctas).filter(
      btn => btn !== 'cta1' && btn !== 'cta2'
    );
    return buttonNames.map(btn => {
      return (
        <Text
          as="a"
          sx={{
            padding: '16px 64px',
            border: ['none', '2px solid'],
            borderColor: 'primary',
            transition: `all .25s`,
            color: ['white', 'primary'],
            '&:hover': {
              boxShadow: `2px 2px 8px rgba(0,0,0,.2)`,
              transition: `all .25s`,
            },
          }}
          target="_blank"
          rel="noopenner"
          href={addHTTP(shoutData.shout.ctas[btn])}
        >
          {btn}
        </Text>
      );
    });
  };

  return (
    <Flex
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          width: '100%',
          height: '100vh',
          zIndex: 1,
          // top: 0,
          // left: 0,
          // width: '100%',
          // height: '100vh',
          zIndex: 0,
          // backgroundImage: `url(${cloudinaryOptimize(
          //   'https://res.cloudinary.com/gonation/image/upload/v1600791845/sites/OnWashington/AGW_Washington_St_Aerials_07.24.20_by_Patrick_Sikes-59.jpg',
          //   2000
          // )})`
          // ,
        }}
      >
        <YouTubeEmbed videoId={'3mbvR7u4344'} />
        {/* <video
          width="100%"
          height="100%"
          controls={false}
          autoPlay
          muted
          loop={true}
          playsInline
          style={{
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <source
            src="https://res.cloudinary.com/gonation/video/upload/v1658249557/sites/OnWashington/on_washington_sono_showcase_2022.mp4_1080p.mp4"
            type="video/mp4"
          />
          <source src="movie.ogg" type="video/ogg" />
        </video> */}
      </Box>
      <Box sx={{ position: 'absolute', top: '20px', left: '20px' }}>
        <Image src={logo} sx={{ width: '300px', height: 'auto' }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          display: 'flex',
        }}
      >
        <Box sx={{ paddingRight: 4 }}>
          <a
            href="https://www.instagram.com/onwashingtonst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram width="30px" fill={'#ffffffe6'}></Instagram>
          </a>
        </Box>
        <Box sx={{ paddingX: 2 }}>
          <a
            href="https://www.facebook.com/onwashingtonst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook width="30px" fill={'#ffffffe6'}></Facebook>
          </a>
        </Box>
      </Box>
    </Flex>
  );
};

export default SplashBlock;
