import React from 'react';
import { Box, Flex, Text, Heading } from 'theme-ui';
import Pulse from 'react-reveal/Pulse';

const CompanyStats = ({
  title,
  style,
  stats = [
    {
      number: '27',
      txt: 'Highly preserved historic buildings',
    },
    {
      number: '50,000',
      txt: 'Square feet',
    },
    {
      number: '250',
      txt: 'Years of combined tenant history',
    },
  ],
}) => {
  return (
    <Box as="section" sx={{ padding: ['2rem 1.5rem', '4rem 1.5rem'], ...style }}>
      {/* <Heading
        as='h3'
        sx={{
          fontSize: [4, '24px'],
          textAlign: 'left',
          '::after': {
            content: '""',
            display: 'block',
            width: 50,
            height: 4,
            bg: 'primary',
            marginTop: 3,
          },
        }}>
        {title}
      </Heading> */}
      <Flex sx={{ flexWrap: 'wrap' }}>
        {stats.map(({ number, txt }) => (
          <Box
            sx={{
              textAlign: 'center',
              flex: '1 1 300px',
              paddingY: 4,
              textAlign: 'center',
            }}>
            <Pulse>
              <Text
                as="h4"
                sx={{
                  fontSize: [7, '120px'],
                  fontWeight: 600,
                  marginBottom: 1,
                }}>
                {number}
              </Text>
            </Pulse>

            <Text as="p" sx={{ fontWeight: 600 }}>
              {txt}
            </Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default CompanyStats;
