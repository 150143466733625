/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { Box, Flex, Button, Text } from 'theme-ui';
import { Link } from 'gatsby';
import HeroLeft from '../assets/hero-left.png';
import circle from '../assets/circle-black.png';
import axios from 'axios';
let jsonpAdapter = require('axios-jsonp');

const MobileSplash = () => {
  const [shoutData, setShoutData] = useState({});

  const shoutURL =
    'https://data.prod.gonation.com/profile/shoutsnew/bzn-PKzc8iPtRTSo0pG0hel46g';

  useEffect(() => {
    axios({
      url: shoutURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setShoutData(res.data);
    });
  }, []);

  const addHTTP = url => {
    const prefix = 'https://';
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url;
    }
    return url;
  };

  const renderCTA = () => {
    const buttonNames = Object.keys(shoutData.shout.ctas).filter(
      btn => btn !== 'cta1' && btn !== 'cta2'
    );
    return buttonNames.map(btn => {
      return (
        <Text
          as='a'
          sx={{ color: 'white' }}
          target='_blank'
          rel='noopenner'
          href={addHTTP(shoutData.shout.ctas[btn])}>
          {btn}
        </Text>
      );
    });
  };
  return (
    <Box sx={{ padding: 3 }}>
      <Flex
        sx={{
          backgroundImage: `url(https://res.cloudinary.com/gonation/image/upload/v1600791845/sites/OnWashington/AGW_Washington_St_Aerials_07.24.20_by_Patrick_Sikes-59.jpg)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '100vh',
          width: '100%',
          flexDirection: 'column',
        }}>
        <Box>
          <img src={circle} alt='' sx={{ width: '60%' }} />
        </Box>

        <Box sx={{ marginTop: 'auto', textAlign: 'center', mb: '140px' }}>
          <Link to='/places'>
            <Button variant='splashButton'>Explore Washington St</Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default MobileSplash;
