/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { Box, Text, Image, Flex } from 'theme-ui';
import { Link } from 'gatsby';
import cloudinaryOptimize from '../helpers/cloudinaryHelper';
import CompanyStats from './CompanyStats';
import axios from 'axios';
let jsonpAdapter = require('axios-jsonp');
const DesktopBusinessShowcase = ({ businesses }) => {
  const [shoutData, setShoutData] = useState({});

  const shoutURL =
    'https://data.prod.gonation.com/profile/shoutsnew/bzn-PKzc8iPtRTSo0pG0hel46g';

  useEffect(() => {
    axios({
      url: shoutURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setShoutData(res.data);
    });
  }, []);

  const addHTTP = url => {
    const prefix = 'https://';
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url;
    }
    return url;
  };
  const stats = [
    {
      number: '27',
      txt: 'Highly preserved historic buildings',
    },
    {
      number: '50,000',
      txt: 'Square feet',
    },
    {
      number: '250',
      txt: 'Years of combined tenant history',
    },
  ];

  const renderCTA = () => {
    const buttonNames = Object.keys(shoutData.shout.ctas).filter(
      btn => btn !== 'cta1' && btn !== 'cta2'
    );
    return buttonNames.map(btn => {
      return (
        <Box sx={{ width: '100%' }}>
          <Text
            as="a"
            sx={{
              display: 'inline-block',
              mt: 3,
              padding: '16px 64px',
              border: ['none', '2px solid'],
              borderColor: 'primary',
              transition: `all .25s`,
              color: ['white', 'primary'],
              '&:hover': {
                boxShadow: `2px 2px 8px rgba(0,0,0,.2)`,
                transition: `all .25s`,
              },
            }}
            target="_blank"
            rel="noopenner"
            href={addHTTP(shoutData.shout.ctas[btn])}
          >
            {btn}
          </Text>
        </Box>
      );
    });
  };
  return (
    <Box
      sx={{
        paddingX: [3, 0],
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${cloudinaryOptimize(
          'https://res.cloudinary.com/gonation/image/upload/v1601067588/sites/OnWashington/full.jpg',
          2000
        )})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // minHeight: '100vh',
        backgroundAttachment: ['unset', 'unset', 'fixed'],
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          margin: 'auto',
          textAlign: 'center',
          paddingY: [3, 3, 5],
        }}
      >
        <Text
          variant="heading"
          as="h1"
          sx={{ color: 'white', fontSize: [4, '26px'] }}
        >
          Great things are always happening OnWashington!
        </Text>
        <Text
          as="p"
          sx={{
            fontSize: [3, '16px'],
            lineHeight: 1.75,
            mb: 0,
            color: 'white',
          }}
        >
          Spearheaded by AGW SoNo Partners LLC, OnWashington shines a spotlight
          on Washington Street Historic District, a vibrant corridor located in
          the coastal community of South Norwalk (SoNo), CT. OnWashington
          features restaurants, retailers, and creative spaces from which to
          operate a business. Join Us OnWashington!
        </Text>
      </Box>
      <Box sx={{ maxWidth: '1350px', margin: 'auto', textAlign: 'center' }}>
        <CompanyStats
          title="Company Stats"
          stats={stats}
          style={{ color: 'white!important' }}
        />
      </Box>
      <Box
        sx={{
          display: ['none', 'none', 'flex'],
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {shoutData.shout ? (
          <Box
            sx={{
              marginRight: 'auto',
              marginLeft: 5,
              maxWidth: '680px',
              margin: 'auto',
              // background: 'rgba(0,0,0,.4)',
              zIndex: 1,
            }}
          >
            <Flex
              sx={{
                alignItems: 'flex-start',
                padding: '1rem',
                border: '2px solid',
                borderColor: 'primary',
                borderRadius: '3px',
              }}
            >
              <Box sx={{ width: '50%' }}>
                <Text
                  variant="heading"
                  sx={{
                    color: 'white',
                    fontSize: 3,
                    fontWeight: 'body',

                    display: 'inline-block',
                    paddingY: 3,
                    paddingX: 3,
                    margin: 0,
                  }}
                >
                  Recent Shout
                </Text>
                <Text
                  variant="heading"
                  sx={{
                    fontSize: [3, 4, '25px'],
                    fontWeight: 'bold',
                    color: 'white',
                    padding: 3,
                    lineHeight: '34px',
                  }}
                >
                  {shoutData && shoutData.shout.text}{' '}
                </Text>
                {shoutData && shoutData.shout.text && shoutData.shout.ctas ? (
                  <Flex
                    sx={{
                      color: 'primary',
                      flexDirection: 'column',
                      mt: [2, 4],
                    }}
                  >
                    {renderCTA()}
                  </Flex>
                ) : (
                  ''
                )}
              </Box>

              <Box sx={{ width: '50%' }}>
                <Image
                  src={`https://res.cloudinary.com/gonation/${shoutData.shout.image.image.cloudinaryId}`}
                  alt={'Recent Shout Announcement'}
                ></Image>
              </Box>
            </Flex>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default DesktopBusinessShowcase;
